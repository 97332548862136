import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'bootstrap/dist/css/bootstrap.min.css'

import '@/assets/icofont/iconfont2.css'
import '@/assets/icofont/iconfont.css'
import '@/assets/common.css'
import '@/assets/common.less'
import '@/assets/flexible'
import axios from 'axios'
import Http from "./request/http";
import Api from "./request/api";

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import topbox from '@/components/Top/Top.vue';

// 全局注册组件
Vue.component('topbox', topbox);

// 瀑布流
import water from "vue-waterfall2";
Vue.use(water);
const load = () => {
  return Vue.prototype.$loading.service({
    lock: true,
    text: '正在计算成绩，请稍等',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.3)'
  });
}
Vue.prototype.$load = load;
Vue.prototype.$http = Http;
Vue.prototype.$api = Api;
Vue.prototype.$axios = axios
Vue.config.productionTip = false

import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
Vue.use(VueDPlayer);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
