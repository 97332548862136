import http from './http'
let examId = JSON.parse(sessionStorage.getItem('examId'))
let userId = JSON.parse(localStorage.getItem('loginInfo'))?.id
export const getExamId = (id) => {
  examId = id
}
export const getUserId = (id) => {
  userId = id
}
export default class Api {
  /**
   * @param {*} params
   * @return
   */

  /**
   * @param {*} data
   * @returns
   */
  //注册
   static async submitnotes(data = {}) {
    return await http.post('/member/auth/register', data)
  }
  //登录
   static async submitsignin(data = {}) {
    return await http.post('/member/auth/login', data)
  }

  //获取用户信息
  static async getUser(partId, params = {}) {
    return await http.get(`/member/user/get`)
  }
  //修改用户信息
  static putUser(data = {}) {
    return http.put('/member/user/update', data)
  }
  //获取 首页分类
  static async gethometab(partId, params = {}) {
    return await http.get(`/yueyi/channel/top-list`)
  }
   //首页  视频list
   static async homeVideo({pageNo, pageSize,searchKeyword,sortField,channelId,subjectId, userId}={}) {
    return await http.get(`/yueyi/video/page`,{pageNo, pageSize,searchKeyword,sortField,channelId,subjectId, userId})
  }
   //首页  文本list
   static async homeText({pageNo, pageSize,searchKeyword,sortField,channelId,subjectId, userId}={}) {
    return await http.get(`/yueyi/article/page`, {pageNo, pageSize,searchKeyword,sortField,channelId,subjectId, userId})
  }
}
