import http from '@/request/http'


/**
 * 获取
 * @returns {Promise | Promise<unknown>}
 */
export const getUnreadNum = ()=>{
  return http.get('/yueyi/notification/get-unread', { })
}

export const getNotificationPage = (params)=>{
  return http.put('/yueyi/notification/page', params)
}

export const read = (id)=>{
  return http.put('/yueyi/notification/read', {id})
}
export const readAll = (type)=>{
  return http.put('/yueyi/notification/read-all', {type})
}
