import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'; // 引入store

Vue.use(VueRouter)

let isrequiresAuth
const token = localStorage.getItem('token');
  if (token) {
    isrequiresAuth =false
  }else{
    isrequiresAuth =true
  }
// user
const user = [
  {
    path: "/user",
    component: () =>
      import("@/views/user/user.vue"),
    meta: {
      title: "个人中心",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
  {
    path: "/PersonalSpace",
    component: () =>
      import("@/views/user/PersonalSpace.vue"),
    meta: {
      title: "个人空间",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
  {
    path: "/Uploadvideo",
    component: () =>
      import("@/views/user/Uploadvideo.vue"),
    meta: {
      title: "上传视频",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
];

// searchDetails 搜索详情
const searchDetails = [
  {
    path: "/searchDetails",
    component: () =>
      import("@/views/searchDetails/searchDetails.vue"),
    meta: {
      title: "搜索详情",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
];

// videoDetails 视频详情
const videoDetails = [
  {
    path: "/videoDetails",
    component: () =>
      import("@/views/videoDetails/videoDetails.vue"),
    meta: {
      title: "视频详情",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },{
    path: "/articleDetails",
    component: () =>
      import("@/views/article/articleDetails.vue"),
    meta: {
      title: "文章详情",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
];

// ranking 排行榜
const ranking = [
  {
    path: "/ranking",
    component: () =>
      import("@/views/ranking/ranking.vue"),
    meta: {
      title: "排行榜",
      requiresAuth: isrequiresAuth,// 确保 requiresAuth 设置为 true
    },
  },
];

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
  },
  ...user,
  ...searchDetails,
  ...ranking,
  ...videoDetails,
]

// 初始化认证状态
store.dispatch('initializeAuth');

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  routes,
})

// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '悦怿';

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
