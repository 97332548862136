import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    token: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = !!token; // 如果token存在，则设置为true
    },
    logout(state) {
      state.token = null;
      state.isAuthenticated = false;
    }
  },
  actions: {
    initializeAuth({ commit }) {
      // 尝试从localStorage获取token
      const token = localStorage.getItem('token');
      if (token) {
        commit('setToken', token);
      }
    },
    logout({ commit }) {
      // 清除localStorage中的token
      localStorage.removeItem('token');
      commit('logout');
    }
  }
});

